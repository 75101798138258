import React from 'react';
import {
  Added,
  Fixed,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="December 2021"
      subnav="release-notes">
      <div id="December2021" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">Winter is here, and December sees us continuing accessibility improvements and bug fixes as well as pushing to finishing new components for the Unified Product experience.</Paragraph>{' '}
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.12.7 - 4.12.18"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                Critical icon next to Help Text in form components with errors.
              </Added>
              <Fixed>
                Error caused by using <code>ids</code> instead of{' '}
                <code>classNames</code> in{' '}
                <Link href="/components/icons/reactions/design">
                  reaction icons
                </Link>
                .
              </Fixed>
            </div>
          </div>

          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Added>
                Accessibility guidelines pages for{' '}
                <Link href="/guidelines/accessibility/design">Overview</Link>{' '}
                and{' '}
                <Link href="/guidelines/accessibility/text/design">Text</Link>.
              </Added>
              <Added>
                Accessibility guidelines to the{' '}
                <Link href="/components/keyboard-shortcut/design">
                  Keyboard Shortcut
                </Link>
                .
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
